import { sortByKey, sumByKey } from '@dendra/utils';
import { Context, allContexts } from '@mydendra/utils/insight-categories';

export type Metric = { month: string; value: number };

export type MetricConfig = {
  chartTitle: string;
  icon: string;
  tableTitle?: string;
  tooltip?: string;
  barColor?: 'teal' | 'yellow' | 'orange';
  valueFunction: (metrics: MetricDataWithType) => number;
  subvalueFunction?: (metrics: MetricDataWithType) => number | null;
  contextsShownIn: Context[];
};

function sum(metric: MetricDataWithType) {
  return (
    metric?.data?.reduce((acc: number, curr: Metric) => acc + curr.value, 0) ??
    null
  );
}

function recent(metric: MetricDataWithType) {
  return metric?.data.at(-1).value ?? null;
}

export const supportedMetrics: Record<MetricType, MetricConfig> = {
  seed_dispersion: {
    chartTitle: 'Seed dispersion',
    icon: 'drone-front',
    barColor: 'teal',
    valueFunction: sum,
    subvalueFunction: fromThisYear,
    contextsShownIn: allContexts,
  },
  seed_collection: {
    chartTitle: 'Seed collection',
    icon: 'bag-seedling',
    barColor: 'yellow',
    valueFunction: sum,
    subvalueFunction: fromThisYear,
    contextsShownIn: allContexts,
  },
  manual_sapling_count: {
    chartTitle: 'Saplings',
    icon: 'sapling',
    tableTitle: 'Saplings',
    tooltip:
      'Population estimate extrapolated from ecologist sampling (10% total area) on ultra high resolution imagery',
    barColor: 'orange',
    valueFunction: recent,
    subvalueFunction: comparedToPrevious,
    contextsShownIn: ['ta-kpi-row', 'ta-list'],
  },
};

export type MetricType =
  | 'seed_collection'
  | 'seed_dispersion'
  | 'manual_sapling_count';
export type MetricDataWithType = { metric_type: MetricType; data: Metric[] };
export type MetricResult = MetricDataWithType[];

/**
 * Takes MetricResult and returns the array with the Metrics aggregated by year.
 *
 * Results are sorted alphabetically by metric type - this is arbitary and may need fine tuning.
 */
export type AggregatedMetricResult = {
  metric_type: MetricType;
  data: { year: number; value: number }[];
}[];

export const aggregateMetricResultByYear = (
  results: MetricResult,
): AggregatedMetricResult =>
  results
    .map(({ metric_type, data }) => ({
      metric_type,
      data: aggregateMetricsByYear(data),
    }))
    .sort(sortByKey('metric_type'));

export const aggregateMetricsByYear = (metricGroup: Metric[]) => {
  const groupedByYear = Object.groupBy(metricGroup, metric =>
    new Date(metric.month).getFullYear().toString(),
  );
  return Object.entries(groupedByYear).map(([year, values]) => ({
    year: +year,
    value: sumByKey(values, 'value'),
  }));
};

function fromThisYear(metric: MetricDataWithType) {
  const aggregatedByYear = aggregateMetricsByYear(metric.data);
  const thisYear = aggregatedByYear.find(
    ({ year }) => year === new Date().getFullYear(),
  );
  return thisYear?.value ?? null;
}

function comparedToPrevious(metric: MetricDataWithType) {
  const latest = metric.data.at(-1);
  const previous = metric.data.at(-2);
  return latest && previous ? latest.value - previous.value : null;
}
