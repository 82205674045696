import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Context } from '@mydendra/utils/insight-categories';
import { FlagService } from '@services/states/flags';
import { of, tap } from 'rxjs';
import { MetricResult, supportedMetrics } from './metrics.utils';

const TARGET_AREA_CACHE: Map<number, MetricResult> = new Map();
const LOCATION_CACHE: Map<number, MetricResult> = new Map();
const COLLECTION_CACHE: Map<number, MetricResult> = new Map();

@Injectable({
  providedIn: 'root',
})
export class MetricService {
  private http = inject(HttpClient);
  private flagService = inject(FlagService);

  getHighlightedMetrics(
    metricResult: MetricResult,
    contexts?: Context[],
  ): MetricResult {
    const supportedMetricTypes = Object.keys(supportedMetrics).filter(
      metricType =>
        contexts
          ? contexts.some(context =>
              supportedMetrics[metricType].contextsShownIn.includes(context),
            )
          : true,
    );
    const hasSaplingFlag = this.flagService.query.getFlag(
      'monitoring-sapling-count',
    );
    const metricTypeSet = new Set(
      metricResult
        .filter(metric => supportedMetricTypes.includes(metric.metric_type))
        .filter(
          metric =>
            !['sapling', 'manual_sapling_count'].includes(metric.metric_type) ||
            hasSaplingFlag,
        )
        .map(metric => metric.metric_type),
    );
    return Array.from(metricTypeSet).map(metricType =>
      metricResult.find(metric => metric.metric_type === metricType),
    );
  }

  /**
   * Retrieves aggregated target area metrics for a location.
   */
  getForTargetArea(targetAreaId: number) {
    if (TARGET_AREA_CACHE.has(targetAreaId)) {
      return of(TARGET_AREA_CACHE.get(targetAreaId));
    }

    return this.http
      .get<MetricResult>(`/api/target-area/${targetAreaId}/metrics/`)
      .pipe(tap(result => TARGET_AREA_CACHE.set(targetAreaId, result)));
  }

  /**
   * Retrieves aggregated target area metrics for a location.
   */
  getForLocation(locationId: number) {
    if (LOCATION_CACHE.has(locationId)) {
      return of(LOCATION_CACHE.get(locationId));
    }

    return this.http
      .get<MetricResult>(`/api/location/${locationId}/metrics/`)
      .pipe(tap(result => LOCATION_CACHE.set(locationId, result)));
  }

  /**
   * Retrieves aggregated target area metrics for a LocationCollection.
   */
  getForLocationCollection(collectionId: number) {
    if (COLLECTION_CACHE.has(collectionId)) {
      return of(COLLECTION_CACHE.get(collectionId));
    }

    return this.http
      .get<MetricResult>(`/api/location-collection/${collectionId}/metrics/`)
      .pipe(tap(result => COLLECTION_CACHE.set(collectionId, result)));
  }

  /**
   * Retrieves aggregated target area metrics for each child location in a LocationCollection.
   */
  getForLocationCollectionByLocation(collectionId: number) {
    return this.http
      .get<
        Record<string, MetricResult>
      >(`/api/location-collection/${collectionId}/metrics-by-location/`)
      .pipe(
        tap(result => {
          Object.entries(result).forEach(([key, value]) =>
            LOCATION_CACHE.set(+key, value),
          );
        }),
      );
  }

  getForLocationByTargetArea(locationId: number) {
    return this.http
      .get<
        Record<string, MetricResult>
      >(`/api/location/${locationId}/metrics-by-target-area/`)
      .pipe(
        tap(result => {
          Object.entries(result).forEach(([key, value]) =>
            TARGET_AREA_CACHE.set(+key, value),
          );
        }),
      );
  }
}
